import { FormControl, FormLabel, SimpleGrid, Switch, Text } from "@chakra-ui/react"
import { useState } from "react"
import { SystemCardsSliceDefaultItem } from "../../../../../prismicio-types"
import { Category } from "../../../../api-clients/product-api-v1"
import { pages } from "../../../../common/constants/constants"
import { useBrandedTranslation } from "../../../../common/hooks/useBrandedTranslation"
import { getCategoryDataById, isCategorySerie, isCategorySystem } from "../../../../common/utils/category-utils"
import SystemsCard from "./SystemsCard"

type Props = {
  systemCategories: Category[]
  seriesCategories?: Category[]
  systemCards: SystemCardsSliceDefaultItem[]
  showAllCategories: boolean
}

const CategoryTiles = ({ systemCategories, seriesCategories = [], systemCards, showAllCategories }: Props) => {
  const { t } = useBrandedTranslation()
  const [expandAll, setExpandAll] = useState(showAllCategories ?? false)
  const hasShowAll = systemCards?.some((systemCard) =>
    isCategorySystem(systemCard?.chapterknot)
      ? getCategoryDataById(systemCategories, systemCard?.chapterknot)?.subCategories?.length > 3
      : isCategorySerie(systemCard?.chapterknot)
      ? getCategoryDataById(seriesCategories, systemCard?.chapterknot)?.subCategories?.length > 3
      : false
  )

  return (
    <>
      {hasShowAll && (
        <FormControl mb="6" justifyContent="flex-end" display="flex" alignItems="center" w="auto">
          <FormLabel htmlFor="show-all-categories" mb="0" pl="4" cursor="pointer">
            <Text fontSize="sm" fontWeight="medium">
              {t("show-all-categories")}
            </Text>
          </FormLabel>
          <Switch
            id="show-all-categories"
            isChecked={expandAll}
            variant="branded"
            onChange={() => setExpandAll(!expandAll)}
          ></Switch>
        </FormControl>
      )}

      <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing="6" alignItems="start" justifyItems="center">
        {systemCards?.map((systemCard: SystemCardsSliceDefaultItem, index: number) => {
          const isSystem = isCategorySystem(systemCard?.chapterknot)
          const isSerie = isCategorySerie(systemCard?.chapterknot)
          const data = isSystem ? systemCategories : isSerie ? seriesCategories : []
          const category = !!data?.length ? getCategoryDataById(data, systemCard?.chapterknot) : null
          const categoryLinks = category?.subCategories
          const hasPictos = isSerie ? false : !!systemCard.show_pictos
          const mainUrl = isSerie
            ? `/${pages.SERIES}/${systemCard?.chapterknot}`
            : `/${pages.SYSTEMS}/${systemCard?.chapterknot}`

          return (
            category && (
              <SystemsCard
                key={systemCard?.chapterknot + index}
                mainUrl={mainUrl}
                headline={category?.name}
                categoryLinks={categoryLinks}
                imageSrc={systemCard?.picture?.url}
                hasPictos={hasPictos}
                expandAll={expandAll}
              />
            )
          )
        })}
      </SimpleGrid>
    </>
  )
}

export default CategoryTiles
