import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Link, Text } from "@chakra-ui/react"

type Props = {
  error: Error
  resetErrorBoundary?: (...args: Array<unknown>) => void
}

// TODO (nan) This is POC stuff

export const ComponentError = ({ error, resetErrorBoundary }: Props) => {
  return (
    <Alert status="error">
      <AlertIcon />
      <Box>
        <AlertTitle>Error!</AlertTitle>
        <AlertDescription>
          <Text>{error.message}</Text>
          <Link onClick={resetErrorBoundary}>Try again</Link>
        </AlertDescription>
      </Box>
    </Alert>
  )
}
