import { SimpleGrid } from "@chakra-ui/react"
import { TeaserCardsSliceDefaultItem } from "../../../../../prismicio-types"
import { TeaserCard } from "./TeaserCard/TeaserCard"

type Props = {
  teaserCards: TeaserCardsSliceDefaultItem[]
}

const TeaserCards = ({ teaserCards }: Props) => {
  return (
    <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing="6" justifyItems="center">
      {teaserCards.map((teaserCardInfo, ind: number) => {
        return <TeaserCard key={`${teaserCardInfo.teaser_card_title}_${ind}`} teaserCardInfo={teaserCardInfo} />
      })}
    </SimpleGrid>
  )
}

export default TeaserCards
