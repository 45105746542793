import { Box, Flex } from "@chakra-ui/react"
import { Category } from "../../../../api-clients/product-api-v1"
import { pages } from "../../../../common/constants/constants"
import { getPictoImage, isCategorySystem, someHavePictos } from "../../../../common/utils/category-utils"
import CustomImage from "../../../shared/CustomImage"
import NavigationButtonLink from "../../../shared/NavigationButtonLink"

type Props = {
  categoryLinks: Category[]
  hasPictos: boolean
}

const CategoryTileLinks = ({ categoryLinks, hasPictos }: Props) => {
  const someCategoriesHavePictos = someHavePictos(categoryLinks)

  return (
    <Flex flexDirection="column" alignItems="flex-start">
      {categoryLinks?.map((catLink) => {
        const catPictoImage = getPictoImage(catLink?.images)
        const hasLargePadding = hasPictos && someCategoriesHavePictos && !catPictoImage?.thumbnail
        const isSystem = isCategorySystem(catLink?.id)
        const href = `/${isSystem ? pages.SYSTEMS : pages.SERIES}/${catLink.id}`

        return (
          <NavigationButtonLink
            key={catLink.id}
            href={href}
            label={catLink.name}
            minH="14"
            _last={{ mr: "12" }}
            pl={hasLargePadding ? { base: "12", sm: "14" } : { base: "0", sm: "2" }}
            leftIcon={
              hasPictos &&
              catPictoImage?.thumbnail && (
                <CustomImage
                  src={catPictoImage.thumbnail}
                  alt="Category image as a picto"
                  w="10"
                  h="10"
                  fallback={<Box w="10" />}
                />
              )
            }
          />
        )
      })}
    </Flex>
  )
}

export default CategoryTileLinks
