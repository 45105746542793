import { AspectRatio, Box, Collapse, Heading, IconButton, Link, useDisclosure } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Category } from "../../../../api-clients/product-api-v1"
import { useBrandedTranslation } from "../../../../common/hooks/useBrandedTranslation"
import CustomImage from "../../../shared/CustomImage"
import CustomLink from "../../../shared/CustomLink"
import CustomTooltip from "../../../shared/CustomTooltip"
import { CChevronDown, CChevronUp } from "../../../shared/Icons"
import CategoryTileLinks from "./CategoryTileLinks"

type Props = {
  imageSrc?: string
  headline: string
  categoryLinks: Category[]
  expandAll: boolean
  hasPictos: boolean
  mainUrl: string
}

const SystemsCard = ({ mainUrl, imageSrc, headline, categoryLinks, expandAll, hasPictos }: Props) => {
  const { t } = useBrandedTranslation()
  const [isExpanded, setIsExpanded] = useState(false)
  const CATEGORIES_TO_SHOW = 3
  const categoryLinksLength = categoryLinks?.length

  /* This makes a controlled tooltip */
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    setIsExpanded(expandAll)
  }, [expandAll])

  return (
    <Box
      maxW="98"
      w="100%"
      bg="gray.100"
      h={expandAll ? "100%" : "auto"}
      minH={{ base: "120.5", sm: "128" }}
      position="relative"
    >
      <Box overflow="hidden" _hover={{ "#mainImg": { transform: "scale(1.2)" } }} _active={{ borderColor: "gray.900" }}>
        <CustomLink href={mainUrl}>
          <Link>
            <AspectRatio ratio={16 / 9}>
              <CustomImage
                id="mainImg"
                style={{ transition: "transform 250ms ease" }}
                src={imageSrc}
                fit="cover"
                alt="Category image"
                loading="eager"
              />
            </AspectRatio>
          </Link>
        </CustomLink>
      </Box>

      <Box p="6">
        <Heading
          as="h3"
          lineHeight="8"
          fontSize={{ base: "xl", sm: "2xl", md: "3xl" }}
          wordBreak="break-word"
          noOfLines={2}
          mb="4"
        >
          <CustomLink href={mainUrl}>
            <Link>{headline}</Link>
          </CustomLink>
        </Heading>

        <CategoryTileLinks categoryLinks={categoryLinks?.slice(0, CATEGORIES_TO_SHOW)} hasPictos={hasPictos} />

        <Collapse in={isExpanded}>
          <CategoryTileLinks
            categoryLinks={categoryLinks?.slice(CATEGORIES_TO_SHOW, categoryLinksLength)}
            hasPictos={hasPictos}
          />
        </Collapse>

        {!expandAll && categoryLinksLength > CATEGORIES_TO_SHOW && (
          <CustomTooltip isOpen={isOpen} label={isExpanded ? t("show-less") : t("show-more")}>
            <IconButton
              icon={isExpanded ? <CChevronUp /> : <CChevronDown />}
              isRound
              aria-label={isExpanded ? "show less" : "show more"}
              onClick={() => {
                onClose()
                setIsExpanded(!isExpanded)
              }}
              variant="categoryBreadcrumb"
              minW={{ base: "11", md: "10" }}
              minH={{ base: "11", md: "10" }}
              position="absolute"
              right="6"
              bottom="6"
              onMouseEnter={onOpen}
              onMouseLeave={onClose}
            />
          </CustomTooltip>
        )}
      </Box>
    </Box>
  )
}

export default SystemsCard
