import { Box, Flex, Text, useBreakpointValue, useDimensions, useDisclosure } from "@chakra-ui/react"
import { useRouter } from "next/router"
import { useEffect, useRef, useState } from "react"
import { brands } from "../../../../../common/constants/constants"
import { ChakraNextLinkButton } from "../../../../shared/ChakraNextLinkButton"
import CustomTooltip from "../../../../shared/CustomTooltip"
import { CArrowRight } from "../../../../shared/Icons"
import TeaserHeading from "./TeaserHeading"

export const TeaserCard = ({ teaserCardInfo }) => {
  const router = useRouter()
  const { brand } = router.query

  const {
    teaser_card_title,
    teaser_card_subtitle,
    teaser_card_description,
    teaser_link_text,
    teaser_link_url,
    teaser_link_text_2,
    teaser_link_url_2,
    teaser_background_color,
  } = teaserCardInfo

  const backgroundColors = {
    brand: "brand.500",
    gray: "gray.150",
  }

  const textColors = {
    brand: "white",
    gray: "black",
  }

  const headingRef = useRef<HTMLDivElement>(null)
  const descriptionRef = useRef<HTMLParagraphElement>(null)
  const linksRef = useRef<HTMLDivElement>(null)
  // 24 is the value in pixels for lineHeight * fontSize (1.5 * 16)
  const pxRowSize = 24

  // This is just needed for the provided event listeners attached to resize
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const descriptionsDimensions = useDimensions(descriptionRef, true)

  const [numberOfLines, setNumberOfLines] = useState(0)
  const [descriptionHeight, setDescriptionHeight] = useState("100%")

  // Teaser card size in pixels for the specified breakpoints ( -64px padding for M and L design )
  const teaserCardSize = useBreakpointValue({ base: Number.POSITIVE_INFINITY, sm: 284, md: 328 })

  const { isOpen, onOpen, onToggle, onClose } = useDisclosure()

  useEffect(() => {
    if (!descriptionRef?.current || !descriptionRef?.current || !descriptionRef?.current) return

    // scrollHeight is the height of the element despite it being in overflow hidden parent
    const scrollHeight = descriptionRef?.current?.scrollHeight

    // availableSpaceForText is the available space for the description component ( the + 8 is the 8px for gap )
    const availableSpaceForText =
      (teaserCardSize ?? 0) -
      (headingRef?.current?.scrollHeight ? headingRef?.current?.scrollHeight + 8 : 0) -
      (linksRef?.current?.scrollHeight ? linksRef?.current?.scrollHeight + 8 : 0)

    if (availableSpaceForText > scrollHeight) {
      setNumberOfLines(0)
      setDescriptionHeight("100%")
    } else {
      const tempNumberOfLines = Math.floor(availableSpaceForText / pxRowSize)
      setNumberOfLines(tempNumberOfLines)
      setDescriptionHeight(`${tempNumberOfLines * pxRowSize}px`)
    }
  }, [
    descriptionRef?.current?.scrollHeight,
    headingRef?.current?.scrollHeight,
    linksRef?.current?.scrollHeight,
    teaserCardSize,
  ])

  return (
    <Flex
      flexDirection="column"
      gap="2"
      p={{ base: "6", sm: "8" }}
      background={backgroundColors[teaser_background_color?.toLocaleLowerCase()]}
      textColor={textColors[teaser_background_color?.toLocaleLowerCase()]}
      minHeight={{ base: "auto", sm: "87", md: "98" }}
      maxH={{ base: "100%", sm: "87", md: "98" }}
      maxW={{ base: "87", md: "98" }}
      w="100%"
    >
      {(teaser_card_title || teaser_card_subtitle) && (
        <Box ref={headingRef}>
          {teaser_card_title && (
            <TeaserHeading label={teaser_card_title} fontWeight={brands[String(brand)]?.subTitleFontWeight} />
          )}
          {teaser_card_subtitle && (
            <TeaserHeading label={teaser_card_subtitle} fontWeight={brands[String(brand)]?.mainTitleFontWeight} />
          )}
        </Box>
      )}

      {teaser_card_description && (
        <Box maxH={descriptionHeight}>
          <CustomTooltip
            label={numberOfLines > 0 ? teaser_card_description : ""}
            maxW={{ base: "80", md: "88" }}
            isOpen={isOpen}
          >
            <Text
              ref={descriptionRef}
              noOfLines={numberOfLines}
              whiteSpace="break-spaces"
              onMouseEnter={onOpen}
              onMouseLeave={onClose}
              onClick={onToggle}
            >
              {teaser_card_description}
            </Text>
          </CustomTooltip>
        </Box>
      )}

      {(teaser_link_text || teaser_link_text_2) && (
        <Flex ref={linksRef} flexDirection="column" alignItems="flex-start" wordBreak="break-word">
          {teaser_link_text && teaser_link_url?.url && (
            <ChakraNextLinkButton
              variant="textLink"
              px="0"
              href={teaser_link_url?.url}
              target={teaser_link_url?.target}
              color="inherit"
              label={teaser_link_text}
              leftIcon={<CArrowRight />}
            />
          )}
          {teaser_link_text_2 && teaser_link_url_2?.url && (
            <ChakraNextLinkButton
              variant="textLink"
              px="0"
              href={teaser_link_url_2?.url}
              target={teaser_link_url_2?.target}
              color="inherit"
              label={teaser_link_text_2}
              leftIcon={<CArrowRight />}
            />
          )}
        </Flex>
      )}
    </Flex>
  )
}
