import { Heading, HeadingProps } from "@chakra-ui/react"

type Props = HeadingProps & {
  label: string
}

const TeaserHeading = ({ label, fontWeight }: Props) => {
  return (
    <Heading as="h3" fontSize={{ base: "3xl", sm: "5xl" }} textTransform="uppercase" fontWeight={fontWeight}>
      {label}
    </Heading>
  )
}

export default TeaserHeading
